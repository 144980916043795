<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <project-form />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectForm from './Form.vue';

export default {
  name: 'create-project',
  components: {
    ProjectForm,
  },
};
</script>

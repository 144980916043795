<template>
  <!--  <div class="view-container">-->
  <!--    <div class="view">-->
  <!--      <div class="view-body">-->
  <el-form v-loading="loadingBody">
    <el-select
        :placeholder="$t('system.select')"
        @change="fetchData"
        filterable
        v-model="trackerId"
    >
      <el-option
          :key="item.id"
          :label="$t(item.translation)"
          :value="item.id"
          v-for="item in trackers"
      >
      </el-option>
    </el-select>
    <form-generator
        :backend_errors="backend_errors"
        :data="data"
        :dictionaries="dictionaries"
        :inputRequiredEmpty="inputRequiredEmpty"
        :mode="mode"
        :schema="schema.containers"
        v-if="schema && schema.containers"
    >
    </form-generator>
    <div class="container-fluid bg-white">
      <div class="row">
        <div class="col-xl-4 col-12 ml-auto">
          <div class="d-flex justify-content-end">
            <el-button
                :loading="saveOrUpdateLoading"
                @click.prevent.stop="onSaveOrUpdate"
                type="primary"
            >
              {{ $t('system.save') }}
            </el-button>
            <el-button @click.native="onCancel">{{ $t('system.cancel') }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-form>
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
// import Vue from 'vue'
import axios from 'axios';
import _ from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import formGenerator from '../../components/vue-form-generator/FromGenerator.vue';
// import Global from '../../helpers/global';
import notification from '../../notification/notify';
import projectTreeBuilder from '../../mixin/projectsTreeBuilder';

export default {
  name: 'ProjectForm',
  mixins: [projectTreeBuilder],
  components: {formGenerator},
  data() {
    return {
      trackerId: null,
      trackers: [],
      inputRequiredEmpty: [],
      backend_errors: {},
      data: {},
      dictionaries: {},
      schema: [],
      loadingBody: true,
      saveOrUpdateLoading: false,
    };
  },
  computed: {
    ...mapGetters('project', ['mode', 'project', 'parentId']),
    // backgroundColor () {
    //   return this.$store.state.app.color
    // }
  },
  async beforeMount() {
    await this.fetchTrackers();
    if (this.$route.params.projectId) {
      this.setParentId(parseInt(this.$route.params.projectId));
    } else {
      this.setParentId(null);
    }
    this.setMode('create');
  },
  beforeDestroy() {
    this.setMode('read');
  },
  watch: {
    mode(newVal) {
      if (newVal) {
        this.fetchData();
      }
    },
  },
  methods: {
    ...mapActions('project', ['setMode', 'setParentId']),
    onCancel() {
      this.$router.back();
    },
    scrollTop() {
      var top = document.body.getElementsByClassName('app-wrapper')[0];
      var scrollStep = -top.scrollTop / (500 / 15);
      var scrollInterval = setInterval(function () {
        if (top.scrollTop !== 0) {
          top.scrollBy(0, scrollStep);
        } else clearInterval(scrollInterval);
      }, 15);
    },
    onSaveOrUpdate() {
      let headers = {
        platform: 'web',
        mode: this.mode,
      };
      this.checkValidation().then(() => {
        this.saveOrUpdateLoading = true;
        const method = this.mode === 'create' ? 'post' : 'put';
        axios[method](`project`, this.data, {
          params: {
            tracker_id: this.trackerId,
          },
          headers: headers,
        }).then((response) => {
          this.$router.push({path: `/project/${response.data.id}`});
          //   this.$store.commit('modal/OPEN_READ_MODAL', id)
          notification.notify(
              this.$t('notify.success'),
              this.$t('system saved'),
              'success');
        }).catch(() => {
          this.saveOrUpdateLoading = false;
          notification.notify(
              this.$t('notify.error'),
              this.$t('system.error'),
              'error');
        });
      });
      // })
    },
    fetchTrackers() {
      return axios.get('trackers', {
        params: {
          submodule: 'Project',
        },
      }).then((response) => {
        this.trackers = response.data.data;
        if (this.trackers.length > 0) {
          const defaultTracker = _.find(this.trackers, ['is_default', 1]);
          if (defaultTracker) {
            this.trackerId = defaultTracker.id;
          } else {
            this.trackerId = this.trackers[0].id;
          }
        }
      });
    },
    fetchData(options = []) {
      this.loadingBody = true;
      let headers = {
        platform: 'web',
        mode: this.mode,
      };
      axios.get(`project/create`, {params: {tracker_id: this.trackerId}, headers: headers}).then((response) => {
        this.data = response.data.data;
        this.dictionaries = response.data.dictionaries;
        if ((options.length === 0 || options.dictionaries) && this.dictionaries && this.dictionaries.projects) {
          this.dictionaries.projects = this.buildProjectTree(this.dictionaries.projects);
        }
        if (this.data && this.data.parent_id) {
          this.data.parent_id = this.parentId;
        }
        this.schema = response.data.schema;
        this.loadingBody = false;
      }).catch((error) => {
        console.log('error', error);
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
>
.el-select-dropdown.el-popper {
  min-width: 250px !important;
}

.form-generator-project {
  form {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    label {
      line-height: 40px;
    }
  }

  .el-input__inner {
    line-height: 40px !important;
    height: 40px !important;
  }

  .el-select, .el-date-editor {
    width: 100% !important;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
}

.validate-error {
  .el-input__inner, .el-input__inner:focus, .el-textarea__inner, .el-textarea__inner:focus, .el-textarea__inner:hover, .el-input__inner:hover {
    border: 1px solid #F56C6C;
  }
}

.disabled-nav {
  color: #fff;
  font-weight: 600;
  border-bottom: 2px solid #fff;
  line-height: 60px;
  padding: 0 20px;
  display: inline-block;
  font-size: 14px;
}
</style>
